import React from "react";

const links = {
  android: "https://play.google.com/store/apps/details?id=com.dimorder.app",
  ios: "https://apps.apple.com/app/apple-store/id1558146057",
};

const App = () => {
  function copy() {
    const hiddenInput = document.querySelector(".hidden-input");
    hiddenInput.value = window.location.href;
    hiddenInput.style.opacity = 0.000001;
    hiddenInput.focus();
    hiddenInput.setSelectionRange(0, hiddenInput.value.length);
    const commandResult = document.execCommand("copy", false, null);
    console.log("copy result", commandResult);
  }

  const handleClick = (platform) => {
    try {
      if (window.location.search) {
        copy();
      }
      window.open(links[platform]);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div>
        <input type="text" className="hidden-input" readOnly="readonly" />
        {/* Mobile */}
        <div className="d-block d-sm-none">
          <section className="bg-image custom-bg-hero custom-bg-left">
            <div
              id="mobile-intro"
              className="vh-100 container d-flex flex-column align-items-center text-center py-5"
            >
              <div className="col-12 flex-grow-1 d-flex flex-column justify-content-center align-items-center">
                <div className="col-3 mb-3">
                  <img
                    className="img-fluid"
                    src={process.env.PUBLIC_URL + "/assets/app logo.png"}
                    aria-label="app logo"
                  />
                </div>
                <div className="mb-4">
                  <h2 className="h5 custom-hashtag-text">
                    #點單 #點止落單咁簡單
                  </h2>
                </div>
                <div className="col-8 row gx-3">
                  <p className="fs-4 mb-2">
                    馬上到 iOS 和 Android 的<br />
                    應用程式商店下載！
                  </p>
                  <div className="col d-inline-block">
                    <button onClick={() => handleClick("ios")}>
                      <img
                        className="custom-download-img img-fluid"
                        src={process.env.PUBLIC_URL + "/assets/apple store v2 logo.svg"}
                        aria-label="apple store download icon"
                      />
                    </button>
                  </div>
                  <div className="col d-inline-block">
                    <button onClick={() => handleClick("android")}>
                      <img
                        className="custom-download-img img-fluid"
                        src={process.env.PUBLIC_URL + "/assets/google play v2 logo.svg"}
                        aria-label="google play download icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-5 py-4">
                <img
                  className="img-fluid"
                  src={process.env.PUBLIC_URL + "/assets/slogan-m.png"}
                  aria-label="全香港滋味，送到你屋企"
                />
              </div>
              <div className="col-2">
                <a href="#mobile-features-1">
                  <img
                    className="img-fluid p-2"
                    src={process.env.PUBLIC_URL + "/assets/scroll-down-button-m.png"}
                    aria-label="scroll to next section button icon"
                  />
                </a>
              </div>
            </div>
          </section>
          <section id="mobile-features-1" className="bg-image custom-bg-hero">
            <div className="vh-100 container d-flex flex-column align-items-center py-5">
              <div className="col-12 flex-grow-1 d-flex flex-column text-center pb-4">
                <div className="row py-2 mb-4">
                  <p className="mb-0">
                    點單係一個香港外賣平台。
                    <br />
                    你可以安坐家中享用全港九新界所有特色美食，
                    <br />
                    不受地區限制。
                  </p>
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/car.png"}
                      className="img-fluid py-2"
                      aria-label="scooter delivery image"
                    />
                  </div>
                </div>
                <div className="row py-2 mb-4">
                  <div className="col-3 mx-auto">
                    <img
                      className="img-fluid"
                      src={process.env.PUBLIC_URL + "/assets/全港配送logo.png"}
                      aria-label="delivery icon"
                    />
                  </div>
                  <h1 className="h2 my-2">全港配送</h1>
                  <p className="m-0">
                    配送距離不受限制，
                    <br />
                    即使其他地區餐廳都可以為你送達餐點。
                  </p>
                </div>
                <div className="row py-2">
                  <div className="col-3 mx-auto">
                    <img
                      className="img-fluid"
                      src={process.env.PUBLIC_URL + "/assets/堂食logo.png"}
                      aria-label="dine-in icon"
                    />
                  </div>
                  <h1 className="h2 my-2">堂食</h1>
                  <p className="m-0">
                    自助落單自助付款，
                    <br />
                    令你享受無憂飲食體驗。
                  </p>
                </div>
              </div>
              <div className="col-2">
                <a href="#mobile-features-2">
                  <img
                    className="img-fluid p-2"
                    src={process.env.PUBLIC_URL + "/assets/scroll-down-button-m.png"}
                    aria-label="scroll to next section button icon"
                  />
                </a>
              </div>
            </div>
          </section>
          <section
            id="mobile-features-2"
            className="bg-image custom-bg-hero custom-bg-right"
          >
            <div className="vh-100 container d-flex flex-column align-items-center text-center py-5">
              <div className="col-12 flex-grow-1 pb-4">
                <div className="row py-2 mb-4">
                  <div className="col-3 mx-auto">
                    <img
                      className="img-fluid"
                      src={process.env.PUBLIC_URL + "/assets/rice coin logo.png"}
                      aria-label="rice coin icon"
                    />
                  </div>
                  <h1 className="h2 my-2">RiceCoin積分</h1>
                  <p className="m-0">
                    所有消費均享高達1%RiceCoin積分回饋，
                    <br />
                    積分可隨時兌換消費優惠。
                  </p>
                </div>
                <div className="row py-2">
                  <div className="col-3 mx-auto">
                    <img
                      className="img-fluid"
                      src={process.env.PUBLIC_URL + "/assets/優惠連賞logo.png"}
                      aria-label="deals icon"
                    />
                  </div>
                  <h1 className="h2 my-2">優惠連賞</h1>
                  <p className="m-0">享受運費優惠及RiceCoin回饋激賞</p>
                </div>
              </div>
              <div className="col-2">
                <a href="#mobile-intro">
                  <img
                    className="img-fluid p-2"
                    src={process.env.PUBLIC_URL + "/assets/scroll-up-button-m.png"}
                    aria-label="scroll to next section button icon"
                  />
                </a>
              </div>
            </div>
          </section>
        </div>
        {/* PC */}
        <div className="d-none d-sm-block">
          <header className="bg-image py-2 custom-bg-header">
            <div className="container-fluid text-end px-4">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/DimOrder/"
                    className="link-light"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/facebook logo.png"}
                      className="p-1 custom-social-link-icon"
                      aria-label="facebook icon"
                    />
                    <span className="fw-bold d-none d-sm-inline align-middle">
                      Facebook
                    </span>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.dimorder.com/" className="link-light">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/website logo.png"}
                      className="p-1 custom-social-link-icon"
                      aria-label="website icon"
                    />
                    <span className="fw-bold d-none d-sm-inline align-middle">
                      Website
                    </span>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.instagram.com/hkdimorder/"
                    className="link-light"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/IG logo.png"}
                      className="p-1 custom-social-link-icon"
                      aria-label="instagram icon"
                    />
                    <span className="fw-bold d-none d-sm-inline align-middle">
                      Instagram
                    </span>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="mailto:info@dimorder.com" className="link-light">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/email logo.png"}
                      className="p-1 custom-social-link-icon"
                      aria-label="email icon"
                    />
                    <span className="fw-bold d-none d-sm-inline align-middle">
                      Contact us
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </header>
          <section className="bg-image py-5 custom-bg-hero">
            <div className="container text-center">
              <div className="row mb-4 justify-content-center">
                {/* <img
                  className="custom-dimorder-logo"
                  src={process.env.PUBLIC_URL + "/assets/dimorder logo.png"}
                  aria-label="DimOrder logo"
                /> */}
              </div>
              <div className="col-lg-8 row mb-5 align-items-center mx-auto">
                <div className="col row align-items-end">
                  <div className="col-auto me-4 me-lg-4 mb-4 mb-lg-0">
                    <img
                      className="custom-app-logo"
                      src={process.env.PUBLIC_URL + "/assets/app logo.png"}
                      aria-label="app logo"
                    />
                  </div>
                  <div className="col text-start border-start border-white px-4">
                    <p className="fs-4 mb-2">
                      馬上到 iOS 和 Android 的應用程式商店下載！
                    </p>
                    <div className="mb-2">
                      <button
                        onClick={() => handleClick("ios")}
                        className="d-inline-block custom-download-img-desktop mb-2 mb-xl-0 me-sm-3"
                      >
                        <img
                          className="custom-download-img img-fluid"
                          src={process.env.PUBLIC_URL + "/assets/apple store v2 logo.svg"}
                          aria-label="apple store download icon"
                        />
                      </button>
                      <button
                        onClick={() => handleClick("android")}
                        className="d-inline-block custom-download-img-desktop"
                      >
                        <img
                          className="custom-download-img img-fluid"
                          src={process.env.PUBLIC_URL + "/assets/google play v2 logo.svg"}
                          aria-label="google play download icon"
                        />
                      </button>
                    </div>
                    <h2 className="mb-0 custom-hashtag-text">
                      #點單 #點止落單咁簡單
                    </h2>
                  </div>
                </div>
                <div className="col-auto">
                  <img
                    className="custom-iphone-img"
                    src={process.env.PUBLIC_URL + "/assets/iphone-img.png"}
                    aria-label="app running image"
                  />
                </div>
              </div>
              <div className="col-4 mx-auto mb-4">
                <img
                  className="img-fluid"
                  src={process.env.PUBLIC_URL + "/assets/slogan.png"}
                  aria-label="全香港滋味，送到你屋企"
                />
              </div>
              <div>
                <a href="#features">
                  <img
                    className="custom-scroll-img"
                    src={process.env.PUBLIC_URL + "/assets/scroll-down-button.png"}
                    aria-label="scroll to next section button icon"
                  />
                </a>
              </div>
            </div>
          </section>
          <section id="features" className="bg-image py-5 custom-bg-features">
            <div className="container text-center">
              <p className="fs-4">
                點單係一個香港外賣平台。
                <br />
                你可以安坐家中享用全港九新界所有特色美食，
                <br />
                不受地區限制。
              </p>
              <img
                src={process.env.PUBLIC_URL + "/assets/car.png"}
                className="img-fluid py-2"
                aria-label="scooter delivery image"
              />
            </div>
          </section>
          <section className="bg-image py-5 custom-bg-feature1">
            <div className="container text-center">
              <img src={process.env.PUBLIC_URL + "/assets/全港配送logo.png"} aria-label="delivery icon" />
              <h1 className="my-3">全港配送</h1>
              <p className="fs-4 m-0">
                配送距離不受限制，
                <br />
                即使其他地區餐廳都可以為你送達餐點。
              </p>
            </div>
          </section>
          <section className="bg-image py-5 custom-bg-feature2">
            <div className="container text-center">
              <img src={process.env.PUBLIC_URL + "/assets/堂食logo.png"} aria-label="dine-in icon" />
              <h1 className="my-3">堂食</h1>
              <p className="fs-4 m-0">
                自助落單自助付款，
                <br />
                令你享受無憂飲食體驗。
              </p>
            </div>
          </section>
          <section className="bg-image py-5 custom-bg-feature3">
            <div className="container text-center">
              <img
                src={process.env.PUBLIC_URL + "/assets/rice coin logo.png"}
                aria-label="rice coin icon"
              />
              <h1 className="my-3">RiceCoin積分</h1>
              <p className="fs-4 m-0">
                所有消費均享高達1%RiceCoin積分回饋，
                <br />
                積分可隨時兌換消費優惠。
              </p>
            </div>
          </section>
          <section className="bg-image py-5 custom-bg-feature4">
            <div className="container text-center">
              <img src={process.env.PUBLIC_URL + "/assets/優惠連賞logo.png"} aria-label="deals icon" />
              <h1 className="my-3">優惠連賞</h1>
              <p className="fs-4 m-0">享受運費優惠及RiceCoin回饋激賞</p>
            </div>
          </section>
          <footer className="bg-image py-2 custom-bg-footer">
            <div className="container-fluid text-end px-4">
              <span>
                Copyright © 2021. All right reserved. Infinity Technology
                Corporation Ltd.
              </span>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default App;
